.footer-container {
    background-color: #242424;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-links {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-links a {
    margin: 0 10px;
}

.logo-links img {
    height: 50px;
    width: auto;
}

@media screen and (max-width: 768px) {
    .footer-container {
        padding: 2rem 5rem 1rem 5rem;
    }

    .logo-links {
        flex-direction: column;
    }

    .logo-links a {
        margin: 10px 0; 
    }

    .logo-links img {
        height: 40px; 
    }
}